import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from 'axios'
import AOS from 'aos'

import Utils from '@/utils/utils'

axios.defaults.baseURL = store.state.base_url === 'http://192.168.1.4:8083/' ? 'http://0.0.0.0:5000/api/web' : store.state.base_url + 'api/web'
axios.defaults.timeout = 100000
axios.defaults.headers.Authorization = 'Bearer ' + localStorage.token
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'

router.beforeEach((to, from, next) => {
  store.commit('is_niming')
  if (to.name !== 'Person_detail' || to.name !== 'Power_detail' || to.name !== 'Book_general') {
    document.title = '历史年轮'
  }
  if (Utils.is_mobile()) {
    if (to.name === 'Mobile_index' || to.name === 'Agreement' || to.name === 'Downloads') {
      next()
    } else {
      next({ name: 'Mobile_index' })
    }
  } else {
    if (to.name === 'Mobile_index' || !to.name) {
      next({ name: 'Person_index' })
    } else {
      next()
    }
  }
  // beforeRouteLeave(to, from, next) {
  //   // 可以在离开该组件时恢复原来的标题，或者根据需要设置不同的标题
  //   document.title = 'Original Page Title';
  //   next();
  // }
})
// router.beforeRouteLeave((to, from, next) => {
//   if (to.name !== 'Person_detail' || to.name !== 'Power_detail' || to.name !== 'Book_general') {
//     document.title = '历史年轮 - 用动态年龄看历史'
//   }
// })
createApp(App).use(ElementPlus).use(store).use(router).mount('#app')
AOS.init({
  offset: 120,
  duration: 1200,
  easing: 'ease-in-sine',
  delay: 360
})
