const isEditing = false
const baseUrl = 'https://www.yearsmap.com/'
// const baseUrl = 'http://0.0.0.0:8005/'
const appInfo = {
  appleId: '6454935128',
  androidId: 'com.yearsmap.app',
  wechatId: '',
  name: '历史年轮',
  tag: 'yearsmap',
  desc: '用动态年龄和向量关系看历史',
  desc_long: '我们试图把古人代入到他的时代背景和周边人物中，一年一年动态的感受他'
}
export {
  isEditing,
  baseUrl,
  appInfo
}
