import axios from 'axios'
import { ElMessage, ElNotification } from 'element-plus'

var Utils = {
  is_weixin () {
    const flag = /(micromessenger)/i.test(navigator.userAgent)
    return flag
  },
  check_is_niming () {
    if (localStorage.token) {
      return false
    }
    return true
  },
  is_ios () {
    const u = navigator.userAgent
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    return isiOS
  },
  is_mobile () {
    const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag
  },
  alert (desc) {
    ElMessage(desc)
  },
  alert_success (desc) {
    ElMessage({ type: 'success', message: desc })
  },
  show_snackbar (desc) {
    ElNotification({
      // title: 'Custom Position',
      message: desc,
      position: 'bottom-right'
    })
  },
  show_loading () {},
  is_need_alert_tips  (e, desc) {
    const tipKey = 'tip_' + e
    if (!localStorage.getItem(tipKey)) {
      localStorage.setItem(tipKey, 1)
      Utils.show_snackbar(desc)
    }
  },
  alert_tip_list (pageStr, tipList) {
    for (var i in tipList) {
      if (tipList[i].tag === 'must') {
        Utils.show_snackbar(tipList[i].desc)
        return
      }
      const tipKey = 'tip_' + pageStr + '_' + tipList[i].tag
      if (!localStorage.getItem(tipKey)) {
        localStorage.setItem(tipKey, 1)
        Utils.show_snackbar(tipList[i].desc)
        return
      }
    }
  },
  load_list (list, tag) {
    var _this = this
    if (list.length === 20) {
      _this.list[tag] = _this.list[tag].concat(list)
      _this.page[tag] = _this.page[tag] + 1
    } else if (list.length !== 0) {
      _this.list[tag] = _this.list[tag].concat(list)
      _this.page[tag] = -1
    } else {
      _this.page[tag] = -1
    }
  },
  can_load_list (tag) {
    var _this = this
    if (_this.loading2 || _this.page[tag] === -1) {
      return false
    }
    _this.loading2 = true
    return true
  },
  set_local_sid (sid) {
    if (localStorage.getItem('token')) {
      this.show.zc = false
      localStorage.removeItem('sid')
      localStorage.removeItem('st')
      return
    }
    if (sid) {
      if (sid[0] !== '1') {
        localStorage.setItem('sid', sid)
        localStorage.setItem('st', new Date().getTime())
      } else {
        if (localStorage.getItem('sid')) {
          var logSid = localStorage.getItem('sid')
          if (logSid[0] === '1') {
            localStorage.setItem('sid', sid)
            localStorage.setItem('st', new Date().getTime())
          }
        } else {
          localStorage.setItem('sid', sid)
          localStorage.setItem('st', new Date().getTime())
        }
      }
    }
  },
  go_to (e, needLogin) {
    needLogin = needLogin || false
    if (!localStorage.getItem('token') && needLogin) {
      this.show.zc = true
      return
    }
    this.$router.push({ name: e.currentTarget.dataset.value })
  },
  show_ask_goods (info) {
    this.$dialog.confirm({
      title: '权限不足',
      showCancelButton: true,
      confirmButtonText: '确定',
      message: '进一步了解大数据起名功能'
    }).then(() => {
      this.$router.push({ name: 'goods', query: { way: 'alert', page: info.page, tag: info.tag } })
    })
  },
  go_to_goods_index (e) {
    if (!localStorage.getItem('token')) {
      this.show.zc = true
      return
    }
    var PageAndTag = e.currentTarget.dataset.value.split('|')
    this.$router.push({ name: 'goods', query: { way: 'button', page: PageAndTag[0], tag: PageAndTag[1] } })
  },
  go_to_app (tag, sid) {
    var urlStr = localStorage.getItem('token') ? '/log_to_sth/' : '/log_to_sth_unlogin/'
    if (!sid) {
      sid = localStorage.getItem('sid')
    }
    axios.post(urlStr, { sth: 'app', tag: tag, sid: sid, is_ios: Utils.is_ios() })
      .then(res => {
      // console.log(res)
        if (res.data.code !== 200) {
          this.alert({ content: res.data.msg })
          return
        }
        if (Utils.is_ios()) {
          location.href = 'https://apps.apple.com/cn/app/id1564874607'
        } else {
          this.$router.push({ name: 'index' })
        }
      })
  },
  go_to_xcx (tag, sid) {
    var urlStr = localStorage.getItem('token') ? '/log_to_sth/' : '/log_to_sth_unlogin/'
    if (!sid) {
      sid = localStorage.getItem('sid')
    }
    axios.post(urlStr, { sth: 'xcx', tag: tag, sid: sid, is_ios: Utils.is_ios() })
      .then(res => {
        if (res.data.code !== 200) {
          this.alert({ content: res.data.msg })
          return
        }
        window.location.href = res.data.data.urlscheme
      })
  },
  formatNumber (n) {
    n = n.toString()
    return n[1] ? n : `0${n}`
  },
  format_time_all (timeStr) {
    const nowDate = new Date() // 新建一个日期对象，默认现在的时间
    const strDate = new Date(timeStr.replace(/-/g, '/'))
    // new Date(dateTime).getTime()
    var localOffset = nowDate.getTimezoneOffset() * 60000 // 获得当地时间偏移的毫秒数

    var d = new Date(strDate - localOffset)

    var minutes = (nowDate.getTime() - d.getTime()) / (1000 * 60)
    // var aa =[d.getMonth() + 1, d.getDate()].map(formatNumber).join('-') + ' ' + [d.getHours(), d.getMinutes()].map(formatNumber).join(':')
    var currentDayZeroStr = `${nowDate.getFullYear()}-${nowDate.getMonth() === 9 || nowDate.getMonth() === 10 || nowDate.getMonth() === 11 ? nowDate.getMonth() + 1 : '0' + (nowDate.getMonth() + 1)}-${nowDate.getDate() < 10 ? '0' + nowDate.getDate() : nowDate.getDate()} 00:00:00`
    var currentDayZero = new Date(currentDayZeroStr.replace(/-/g, '/'))
    var minutesZero = (currentDayZero.getTime() + localOffset - strDate.getTime()) / (1000 * 60)

    if (minutes < 1) {
      return '刚刚'
    } else if (minutes < 60) {
      return parseInt(minutes).toString() + '分钟前'
    } else if (minutesZero < 0) {
      return '今天 ' + [d.getHours(), d.getMinutes()].map(Utils.formatNumber).join(':')
    } else if (minutesZero < 24 * 60) {
      return '昨天 ' + [d.getHours(), d.getMinutes()].map(Utils.formatNumber).join(':')
    } else if (minutesZero < 48 * 60) {
      return '前天 ' + [d.getHours(), d.getMinutes()].map(Utils.formatNumber).join(':')
    } else {
      const isNowYear = d.getFullYear() === nowDate.getFullYear()
      if (isNowYear) {
        return [d.getMonth() + 1, d.getDate()].map(Utils.formatNumber).join('-') + ' ' + [d.getHours(), d.getMinutes()].map(Utils.formatNumber).join(':')
      } else {
        return [d.getFullYear(), d.getMonth() + 1, d.getDate()].map(Utils.formatNumber).join('-') + ' ' + [d.getHours(), d.getMinutes()].map(Utils.formatNumber).join(':')
      }
    }
  }
}
export default Utils
