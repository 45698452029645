<template>
  <div id="app">
    <keep-alive>
        <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" ></router-view>
  </div>
</template>

<script>
import store from '@/store'
import axios from 'axios'
import Utils from '@/utils/utils'

export default {
  store,
  mounted (e) {
    store.commit('is_niming')
    axios.post(Utils.check_is_niming() ? '/log_come_unlogin/' : '/log_come/', { launch_info: {} })
      .then(res => {
        if (res.data.code !== 200) {
          Utils.alert(res.data.msg)
        }
        if (res.data.data.token) {
          localStorage.setItem('token', res.data.data.token)
        }
        if (res.data.data.is_vip) {
          store.state.is_vip = res.data.data.is_vip
        }
      })
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: rgb(243, 247, 251);
}
#app{
  background: rgb(243, 247, 251);
}
.tab_select{
  background: lightblue;
}
.tab{
  padding: 2px 0px;
  cursor:pointer;
  border: 1px solid #eee;
}
.tab p{
  display: block;
  height: 10px;
  line-height: 10px;
}
.mod{
  text-align: left;
  padding: 30px 40px;
}
.footer{
  display: block;
  text-align: left;
  padding: 50px 0px;
}
.title_tag{
  margin: 10px;
  display: block;
}
.img_window_big .img_repaet,.img_window .img_repaet,.head_view .img_repaet{
  opacity: 0.4;
}
.green{
  color: #67c23a;
}
.color_caotou{
  background: #00BFFF;
}
.color_zhengti{
  background: #F08080;
}
.color_baotu{
  background: #48D1CC;
}
/* .color_gancao{
  background: #c77eb5;
} */
a {
  text-decoration: none;
  color: inherit;
}
.el-overlay .el-dialog .el-dialog__header {
  display: none;
}
.el-overlay .el-dialog .el-dialog__body{
  padding: 0px;
}
</style>
