import { createStore } from 'vuex'
import { baseUrl } from '@/components/config.js'

export default createStore({
  state: {
    base_url: baseUrl,
    base_img: baseUrl + 'static/img/',
    niming: true,
    is_vip: false,
    weixin_id: 'finekewei'
  },
  mutations: {
    is_niming () {
      if (localStorage.token) {
        this.state.niming = true
      } else {
        this.state.niming = false
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
