import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('../views/mine/Mine.vue')
  },
  {
    path: '/marks',
    name: 'Marks',
    component: () => import('../views/mine/Marks.vue')
  },

  {
    path: '/index',
    name: 'Mobile_index',
    component: () => import('../views/other/Mobile_index.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/other/About.vue')
  },
  {
    path: '/gratitude',
    name: 'Gratitude',
    component: () => import('../views/other/Gratitude.vue')
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: () => import('../views/other/Agreement.vue')
  },
  {
    path: '/Login_wechat',
    name: 'Login_wechat',
    component: () => import('../views/other/Login_wechat.vue')
  },
  {
    path: '/',
    name: 'Person_index',
    component: () => import('../views/Person_index.vue')
  },
  {
    path: '/search',
    name: 'Person_search',
    component: () => import('../views/Person_search.vue')
  },
  {
    path: '/powers',
    name: 'Power_index',
    component: () => import('../views/Power_index.vue')
  },
  {
    path: '/ranks',
    name: 'Rank_index',
    component: () => import('../views/Rank_index.vue')
  },
  {
    path: '/rank/:id',
    name: 'Rank_detail',
    component: () => import('../views/Rank_detail.vue')
  },
  {
    path: '/rank_edit/:id',
    name: 'Rank_detail_edit',
    component: () => import('../views/Rank_detail_edit.vue')
  },
  {
    path: '/person/:id',
    name: 'Person_detail',
    component: () => import('../views/Person_detail.vue')
  },
  {
    path: '/power/:id',
    name: 'Power_detail',
    component: () => import('../views/Power_detail.vue')
  },
  {
    path: '/books',
    name: 'Book_index',
    component: () => import('../views/Book_index.vue')
  },
  {
    path: '/chapter/:id',
    name: 'Book_chapter',
    component: () => import('../views/Book_chapter.vue')
  },
  {
    path: '/book/:id',
    name: 'Book_general',
    component: () => import('../views/Book_general.vue')
  },
  {
    path: '/downloads',
    name: 'Downloads',
    component: () => import('../views/other/DownLoads.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
